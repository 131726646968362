import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, vModelSelect as _vModelSelect, mergeProps as _mergeProps, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "qa-select-container" }
const _hoisted_2 = { class: "qa-select--label" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _renderSlot(_ctx.$slots, "label")
    ]),
    _withDirectives(_createElementVNode("select", _mergeProps({
      class: "qa-select",
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value) = $event))
    }, _ctx.$attrs), [
      _renderSlot(_ctx.$slots, "default")
    ], 16), [
      [_vModelSelect, _ctx.value]
    ])
  ]))
}