
import { defineComponent, onMounted, ref } from 'vue';
import SelectVerseList from '@/components/molecules/SelectVerseList.vue';
import SelectVerseRange from '@/components/molecules/SelectVerseRange.vue';
import Card from '@/components/content/Card.vue';
import { VerseListLimitedResponse } from '@/api/modules/verseList/verseListApi.interfaces';
import { useStore } from '@/store/store';
import Button from '@/components/form/Button.vue';
import Icon from '@/components/content/Icon.vue';
import { useRouter } from 'vue-router';
import { PATH } from '@/router/router';
import { checkForSession } from '@/setupFunctions/session/session';

export default defineComponent({
  name: 'SessionSetup',
  setup() {
    const store = useStore();

    const router = useRouter();

    const state = ref<'selectVerseList' | 'selectRange'>('selectVerseList');

    async function selectVerseList(verseList: VerseListLimitedResponse) {
      await store.dispatch('session/stopSession');
      store.commit('session/setSelectedVerseListId', verseList._id);
      await store.dispatch('session/fetchVerseList');
      state.value = 'selectRange';
    }

    async function selectVerseRange(finalVerseIndex: number) {
      store.dispatch('session/startSession', finalVerseIndex + 1);
      router.push(PATH.quote);
    }

    onMounted(() => {
      checkForSession();
    });

    return {
      state,
      selectVerseList,
      selectVerseRange,
    };
  },
  components: {
    SelectVerseList,
    Card,
    SelectVerseRange,
    Button,
    Icon,
  },
});
