import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createElementVNode as _createElementVNode, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = { class: "empty-layout" }
const _hoisted_2 = { class: "header-links" }
const _hoisted_3 = { class: "el-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.availableLinks, (link) => {
        return (_openBlock(), _createBlock(_component_router_link, {
          key: link.to,
          to: link.to,
          class: "header-link"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(link.label), 1)
          ]),
          _: 2
        }, 1032, ["to"]))
      }), 128))
    ]),
    _createElementVNode("div", _hoisted_3, [
      _renderSlot(_ctx.$slots, "default")
    ])
  ]))
}