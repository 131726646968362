
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'QaSpinner',
  props: {
    delay: {
      type: Number,
      default: 200,
    },
  },
  data: () => ({
    display: false,
    timerId: -1,
  }),
  mounted() {
    if (this.delay > 0) {
      this.timerId = setTimeout(() => {
        this.display = true;
      }, this.delay);
    } else {
      this.display = true;
    }
  },
  beforeUnmount() {
    if (this.timerId < 0) {
      clearTimeout(this.timerId);
    }
  },
});
