
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'QaPlaceholderText',
  props: {
    length: {
      type: Number,
      default: 10,
    },
  },
  computed: {
    text(): string {
      return '█'.repeat(this.length);
    },
  },
});
