
import { useStore } from '@/store/store';
import { computed, defineComponent } from 'vue';
import FullscreenOverlay from './FullscreenOverlay.vue';
import Modal from './Modal.vue';
import Drawer from './Drawer.vue';
import {
  DrawerConfig,
  FullscreenOverlayConfig,
  ModalConfig,
} from '@/store/modules/ui/ui.interfaces';

export default defineComponent({
  name: 'QaOverlays',
  components: {
    FullscreenOverlay,
    Modal,
    Drawer,
  },
  setup() {
    const store = useStore();

    const fullscreens = computed(() => {
      return store.state.ui.overlays.filter(
        (o) => o.type === 'fullscreen'
      ) as FullscreenOverlayConfig[];
    });

    const modals = computed(() => {
      return store.state.ui.overlays.filter((o) => o.type === 'modal') as ModalConfig[];
    });

    const drawers = computed(() => {
      return store.state.ui.overlays.filter((o) => o.type === 'drawer') as DrawerConfig[];
    });

    function closeOverlay(id: string) {
      store.dispatch('ui/closeOverlay', id);
    }

    return {
      fullscreens,
      modals,
      drawers,
      closeOverlay,
    };
  },
});
