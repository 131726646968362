import { withModifiers as _withModifiers, createElementVNode as _createElementVNode, resolveDynamicComponent as _resolveDynamicComponent, toHandlers as _toHandlers, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", {
      class: "qa-drawer--bg cursor--pointer",
      onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (!_ctx.data?.ignoreBgClick ? _ctx.$emit('close', _ctx.data?.id) : () => {}), ["prevent"]))
    }),
    _createElementVNode("div", {
      class: _normalizeClass(["qa-drawer", { [`qa-drawer--${_ctx.computedEdge}`]: !!_ctx.computedEdge }])
    }, [
      (_ctx.data?.component)
        ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.data?.component), _mergeProps({ key: 0 }, _ctx.data?.componentAttr, _toHandlers(_ctx.data?.componentListeners || {}), {
            class: _ctx.data?.componentClasses,
            onClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('close', _ctx.data?.id)))
          }), null, 16, ["class"]))
        : _createCommentVNode("", true),
      (_ctx.data?.closeButton)
        ? (_openBlock(), _createElementBlock("button", {
            key: 1,
            class: "qa-close-button clear-btn cursor--pointer",
            onClick: _cache[2] || (_cache[2] = _withModifiers(($event: any) => (_ctx.$emit('close', _ctx.data?.id)), ["prevent"]))
          }, [
            _createVNode(_component_Icon, { name: "close" })
          ]))
        : _createCommentVNode("", true)
    ], 2)
  ]))
}