
import { computed, defineComponent, ref } from 'vue';

export default defineComponent({
  name: 'QaRange',
  props: {
    modelValue: {
      type: [String, Number],
    },
    min: {
      type: Number,
      default: 0,
    },
    max: {
      type: Number,
      default: 100,
    },
    tooltip: {
      type: [String, Number],
      default: '',
    },
    hideTooltip: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    isIOS: !!navigator.platform.match(/iPhone|iPod|iPad/),
  }),
  methods: {
    iosFix(e: TouchEvent) {
      // Should only be bound for iOS devices to fix a range issue if beginning touch
      // from outside of the range thumb
      const slider = e.target;
      if (
        this.isIOS &&
        slider instanceof HTMLInputElement &&
        e.touches &&
        e.touches.length === 1
      ) {
        e.preventDefault();
        this.active = true;
        const touch = e.touches && e.touches.item(0);
        const sliderRect = slider.getBoundingClientRect();
        const val =
          (touch!.pageX - sliderRect.left) / (sliderRect.right - sliderRect.left);
        let max = Number(this.max) || 100;
        const min = Number(this.min) || 1;
        const segment = 1 / (++max - min),
          segmentArr = [];

        for (let i = 0; i <= max - min; i++) {
          segmentArr.push(segment * i);
        }

        const segCopy = segmentArr.slice(),
          ind = segmentArr.sort((a, b) => Math.abs(val - a) - Math.abs(val - b))[0];

        this.value = String(segCopy.indexOf(ind));
      }
    },
  },
  emits: {
    'update:modelValue': null,
  },
  setup(props, { emit }) {
    const value = computed({
      get: () => props.modelValue,
      set: (value) => emit('update:modelValue', Number(value)),
    });

    const active = ref(false);

    const left = computed(() => {
      const val = Number(value.value || 0);
      const leftNum = Number((val - props.min) * 100) / (props.max - props.min);
      return leftNum;
    });

    const rangeEl = ref<HTMLElement | null>(null);
    const outputEl = ref<HTMLElement | null>(null);

    const correction = computed(() => {
      const total = props.max - props.min;
      const val = Number(value.value || 0);
      const maxDiff = props.max - val;
      const minDiff = val - props.min;
      const sign = maxDiff > minDiff ? -1 : 1;
      const offset = Math.min(maxDiff, minDiff);
      const edgeOffset = offset / total;

      return sign * (0.5 - edgeOffset);
    });

    return {
      value,
      left,
      active,
      correction,
      rangeEl,
      outputEl,
    };
  },
});
