
import { FullscreenOverlayConfig } from '@/store/modules/ui/ui.interfaces';
import { defineComponent, PropType } from 'vue';
import Icon from '@/components/content/Icon.vue';

export default defineComponent({
  name: 'QaFullscreenOverlay',
  props: {
    data: {
      type: Object as PropType<FullscreenOverlayConfig>,
      default: Object,
    },
  },
  components: { Icon },
});
