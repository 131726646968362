
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'QaCard',
  props: {
    elevation: {
      type: Number,
      default: 0,
    },
  },
});
