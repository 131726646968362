
import { useStore } from '@/store/store';
import { computed, defineComponent, onMounted, ref, watch } from 'vue';
import { DIVISION_ORDER } from '@/constants/divisions';
import Select from '@/components/form/Select.vue';
import Icon from '@/components/content/Icon.vue';
import Button from '@/components/form/Button.vue';
import PlaceholderText from '@/components/form/PlaceholderText.vue';
import Spinner from '@/components/atoms/Spinner.vue';

export default defineComponent({
  name: 'QaSelectVerseList',
  components: {
    Select,
    Icon,
    Button,
    PlaceholderText,
    Spinner,
  },
  emits: ['select'],
  setup() {
    const store = useStore();

    const vlYears = computed(() =>
      (
        Array.from(
          store.state.verseList.verseLists.reduce((s, vl) => s.add(vl.year), new Set())
        ) as number[]
      )
        .sort()
        .reverse()
    );

    const selectedYear = ref<number>((vlYears.value && vlYears.value[0]) || -1);

    const vlDivisions = computed(() =>
      store.state.verseList.verseLists
        .filter((vl) => vl.year === selectedYear.value)
        .map((vl) => vl.division)
        .sort((a, b) => DIVISION_ORDER.indexOf(a) - DIVISION_ORDER.indexOf(b))
    );

    const selectedDivision = ref<string>(
      (vlDivisions.value && vlDivisions.value[0]) || ''
    );

    const selectedVl = computed(() =>
      store.state.verseList.verseLists.find(
        (vl) => vl.year === selectedYear.value && vl.division === selectedDivision.value
      )
    );

    watch([selectedYear], () => {
      if (!selectedVl.value) {
        selectedDivision.value = (vlDivisions.value && vlDivisions.value[0]) || '';
      }
    });

    onMounted(async () => {
      await store.dispatch('verseList/fetchVerseLists');
      selectedYear.value = (vlYears.value && vlYears.value[0]) || -1;
      selectedDivision.value = (vlDivisions.value && vlDivisions.value[0]) || '';
    });

    return {
      vlYears,
      selectedYear,
      vlDivisions,
      selectedDivision,
      selectedVl,
    };
  },
});
