
import { defineComponent } from 'vue';
import SessionSetup from '@/components/organisms/SessionSetup.vue';

export default defineComponent({
  name: 'HomeView',
  components: {
    SessionSetup,
  },
});
