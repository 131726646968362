
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'QaButton',
  props: {
    light: {
      type: Boolean,
      default: false,
    },
    pill: {
      type: Boolean,
      default: false,
    },
    text: {
      type: Boolean,
      default: false,
    },
  },
});
