import { withModifiers as _withModifiers, createElementVNode as _createElementVNode, resolveDynamicComponent as _resolveDynamicComponent, toHandlers as _toHandlers, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "qa-modal--default"
}
const _hoisted_2 = {
  key: 0,
  class: "qa-modal--title"
}
const _hoisted_3 = {
  key: 1,
  class: "qa-modal--body"
}
const _hoisted_4 = { class: "qa-modal--button-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", {
      class: "qa-modal--bg cursor--pointer",
      onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (!_ctx.data?.ignoreBgClick ? _ctx.$emit('close', _ctx.data?.id) : () => {}), ["prevent"]))
    }),
    _createElementVNode("div", {
      class: _normalizeClass(["qa-modal", _ctx.data?.containerClasses])
    }, [
      (_ctx.data?.component)
        ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.data?.component), _mergeProps({ key: 0 }, _ctx.data?.componentAttr, _toHandlers(_ctx.data?.componentListeners || {}), {
            class: _ctx.data?.componentClasses,
            onClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('close', _ctx.data?.id)))
          }), null, 16, ["class"]))
        : (_openBlock(), _createElementBlock("div", _hoisted_1, [
            (_ctx.data?.title)
              ? (_openBlock(), _createElementBlock("h3", _hoisted_2, _toDisplayString(_ctx.data?.title), 1))
              : _createCommentVNode("", true),
            (_ctx.data?.body)
              ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString(_ctx.data?.body), 1))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_4, [
              (_ctx.data?.mainButton)
                ? (_openBlock(), _createBlock(_component_Button, {
                    key: 0,
                    class: "qa-modal--button",
                    onClick: _withModifiers(_ctx.primary, ["prevent"])
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.data?.mainButton), 1)
                    ]),
                    _: 1
                  }, 8, ["onClick"]))
                : _createCommentVNode("", true),
              (_ctx.data?.secondaryButton)
                ? (_openBlock(), _createBlock(_component_Button, {
                    key: 1,
                    class: "qa-modal--button",
                    onClick: _withModifiers(_ctx.secondary, ["prevent"]),
                    light: ""
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.data?.secondaryButton), 1)
                    ]),
                    _: 1
                  }, 8, ["onClick"]))
                : _createCommentVNode("", true)
            ])
          ])),
      (_ctx.data?.closeButton)
        ? (_openBlock(), _createElementBlock("button", {
            key: 2,
            class: "qa-close-button clear-btn cursor--pointer",
            onClick: _cache[2] || (_cache[2] = _withModifiers(($event: any) => (_ctx.$emit('close', _ctx.data?.id)), ["prevent"]))
          }, [
            _createVNode(_component_Icon, { name: "close" })
          ]))
        : _createCommentVNode("", true)
    ], 2)
  ]))
}